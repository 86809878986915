import * as Sentry from "@sentry/nextjs";
import { documentAPI, firebase } from 'api';
import { useAtomValue } from 'jotai';
import Head from "next/head";
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from "querystring";
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { User, eFSColl, iGuest, iUser, iUserSecrets, newID } from 'typings';
import Button from '../components/1_atom/buttons/button';
import Layout from '../components/3_organism/layout/layout';
import { useAuth } from '../context/authContext';
import { lastRouterPathState, lastUsedLocationState, loginRedirectURIState } from '../global/state/global';
import Styles from './extras/styles/login.module.scss';


const LoginLogout = () => {
  
  const guestAPI = documentAPI().guestAPI
  const redirectURI = useAtomValue(loginRedirectURIState)
  const lastRouterPath = useAtomValue(lastRouterPathState)
  const lastUsedLocation = useAtomValue(lastUsedLocationState)
  const auth = useAuth()
  const router = useRouter()
  const hasCalledAPI = useRef(false)
  const db = firebase.firestore();
  const [showErrorFeedback, SetShowErrorFeedback] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      SetShowErrorFeedback(true)
    }, 8000);
  }, [])

  const discordLogin = async (query: ParsedUrlQuery) => {
    const code = query?.code as string
    const state = query?.state as string
    if(!code){
      console.error("Cannot login - code missing");
      return
    }
    if(!state){
      console.error("Cannot login - state missing");
      return
    }
    if(hasCalledAPI.current === true) {
      // console.log("hasCalledAPI true")
      return
    }
    hasCalledAPI.current = true

    // Verify and remove state param
    const storedState = sessionStorage.getItem('oauthState');
    if (state !== storedState) {
      console.error('Login state mismatch');
      return;
    }
    sessionStorage.removeItem('oauthState');

    try {
      const redirect = redirectURI || encodeURIComponent(window.location.origin + "/login")
      if(!redirect) throw new Error("Discord login - No redirect")
      // todo: check local data for discord existing token and use that to login without authorising 
      const uri = `/api/discord?code=${code}&redirect=${redirect}`
      const discordLoginData = await fetch(uri, {
        credentials: "include",
      })
        .then((result) => result.json())
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        })
        
      Sentry.addBreadcrumb({
        level: "info",
        type: "discordLoginData",
        data: {
          discordLoginData: discordLoginData
        },
        timestamp: Date.now()
      })

      if(discordLoginData?.firebaseToken) {
        return auth.tokenSignin(
          discordLoginData.firebaseToken,
          (user: firebase.User) => checkIfUserExist(user, discordLoginData)
        )
      }

    } catch (error) {
      console.error("Couldn't fetch discord data", error);
    }
  }

  const checkIfUserExist = async (user: firebase.User, discordUserData?: any) => {

    if(!user) {
      console.error("Create user - no auth user", user, discordUserData);
      return
    }

    const userDocRef = db.collection(eFSColl.USERS).doc(user.uid)
    const firestoreUser = await userDocRef.get().then((doc) => {
      const data = doc.data() as iUser
      if(!data) return undefined
      return data
    });
    
    // add characters from guest
    const guestData: Partial<iGuest> = {}
    const guest = await guestAPI.get(discordUserData?.discordUser?.id)
    if(!!guest){
      guestData.characters = guest.characters
      guestData.signupSets = guest.signupSets
    }
    
    if(firestoreUser === undefined) {
      const userData = createUserData(user, discordUserData, guestData)
      setUserAndPrivateDoc(user, userData.user, userData.userSecrets, !!guest)
      return 
    }

    console.log("found firestoreUserDocument", firestoreUser)
    const userData = createUserData(user, discordUserData, guestData)
    refreshDiscordData(user, userData.user, userData.userSecrets)
    return firestoreUser
  };

  const createUserData = (user: firebase.User, discordUserData?: any, guestData?: Partial<iGuest>) => {
    const userAvatar = discordUserData?.discordUser?.avatar ? `https://cdn.discordapp.com/avatars/${discordUserData?.discordUser.id}/${discordUserData?.discordUser.avatar}.png` : null
    const displayName = discordUserData.discordUser?.username || `user-${newID(6)}`
    const discordUID = discordUserData?.discordUser?.username ? `${discordUserData?.discordUser?.username}#${discordUserData?.discordUser?.discriminator}` : ""
    
    const email = discordUserData.discordUser?.email
    const discordUserGuilds = discordUserData.discordUserGuilds
    const discordUser = discordUserData?.discordUser

    const userSecrets: iUserSecrets = {}
    if(!!email) userSecrets.email = email
    if(!!discordUserGuilds) userSecrets.discordUserGuilds = discordUserGuilds
    if(!!discordUser) userSecrets.discordUser = discordUser
    
    let finalUser = User().create({
      userID: user.uid,
      discordID: discordUserData?.discordUser.id,
      displayName,
      themePreference: "dark",
      discordUID,
      img: userAvatar || undefined,
      locale: discordUserData.discordUser?.locale,
      mainLocation: lastUsedLocation || undefined,
      timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || undefined
    })
    finalUser = Object.assign(finalUser, guestData)

    return {
      userSecrets,
      user: finalUser
    }
  }

  const setUserAndPrivateDoc = async (user: firebase.User, userData: Partial<iUser>, userSecrets: iUserSecrets, wasGuest: boolean) => {
    db.collection(eFSColl.USERS).doc(user.uid)
      .set({ ...userData }, { merge: true })
      .then((response) => {
        console.log("UserDocument successfully written!", response);
      })
      .catch((error) => {
        console.error("Couldn't create user - Error writing document: ", error);
        toast.error(
          `Couldn't set user 😬. Please try again, if the problem persists, please let us know on the discord server`,
        );
      });

    db.collection(eFSColl.USERS).doc(user.uid).collection('private').doc('secrets')
      .set(userSecrets, { merge: true })
      .then((response) => {
        console.log("User secrets document successfully written!", response);
      })
      .catch((error) => {
        console.error("Couldn't create user - Error writing document: ", error);
        toast.error(
          `Couldn't set user 😬. Please try again, if the problem persists, please let us know on the discord server`,
        );
      });

    if(wasGuest && userData?.discordID) await guestAPI.remove(userData.discordID)

    router.replace(lastRouterPath || "/")
  }

  const refreshDiscordData = (user: firebase.User, userData: iUser, userSecrets: iUserSecrets) => {
    const mergeUserData: Partial<iUser> = {
      discordUID: userData.discordUID,
      discordID: userData.discordID,
      locale: userData.locale
    }

    setUserAndPrivateDoc(user, mergeUserData, userSecrets, false)
  }

  if(router?.query?.code) {
    discordLogin(router?.query)
    return (
      <Layout>
        <div className="flex-col center-center" style={{ height: "100%", width: "100%", minHeight: 400 }}>
          {!showErrorFeedback && <h3>Logging you in...</h3>}
          {!!showErrorFeedback && (
            <div className="flex-col gap1 align-center">
              <h4>Struggling with logging in?</h4>
              <p>Take a look at the frequently asked questions in the 'website' tab.</p>
              <Link href={{ pathname: "/faq" }}>
                <Button 
                  text="FAQ"
                  size="small"
                  addClass="clickable"
                />
              </Link>
            </div>
          )}
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <Head>
        <title>Login</title>
        <link rel="canonical" href="https://prepi.io" />
      </Head>
      <div className={Styles.loginContainer}>
        <a href={`https://discord.com/api/oauth2/authorize?client_id=847775163105148958&redirect_uri=${redirectURI}&response_type=code&scope=identify%20email%20guilds`} >
          <Button 
            text="Login with Discord"
            size="small"
            addClass="clickable mr1"
          />
        </a>
      </div>
    </Layout>
  );
}

export default LoginLogout;